import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
//import moment from 'moment';
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { Action, actionId } from '../../../../../entities/Action';
import { WizardStep, WizardTypes } from '../../../../../entities/Wizard';
import { useCustomerData } from '../../../../../hooks/useCustomerData';
import { useHubActions } from '../../../../../hooks/useHubActions';
import { useWizard } from '../../../../../hooks/useWizard';
import { Scroller } from '../../../../Scroller';
import WizardActions from '../components/WizardActions';
import WizardButton from '../components/WizardButton';
import WizardCardActions from '../components/WizardCardActions';
import WizardCharts from '../components/WizardCharts';
import { WizardInnerCard, WizardLoader, WizardPrimaryCard } from '../components/WizardCommonComponents';
import { WizardDescription } from '../components/WizardDescription';
import WizardHeader from '../components/WizardHeader';
import WizardOutdatedAlert from '../components/WizardOutdatedAlert';
import WizardPageTitle from '../components/WizardPageTitle';
import WizardPickList from '../components/WizardPickList';
import WizardSuggestions from '../components/WizardSuggestions';
import WizardTitle from '../components/WizardTitle';

export default function WizardStepStep(): JSX.Element | null {
  const { wizardStep, clickedButton, setClickedButton, readWizardStepRequest, readWizardStep } = useWizard();
  const { customerId } = useCustomerData();
  const { resultsById, runHubActionRequests } = useHubActions();
  const [blockingTimeout, setBlockingTimeout] = useState(0);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const handleErrorDialogClose = () => setErrorDialogOpen(false);
  const { t } = useTranslation();

  const checkStatusAction = useCallback(() => {
    const wizardData = (wizardStep as WizardStep)?.data;
    const action_wizard = wizardData.actions_results.filter((item) => item.wizard_id === wizardData?.wizard_id);
    if (action_wizard.length) {
      return true;
    }
    return wizardData?.actions.some((item: Action) => {
      const actionResult = resultsById[actionId(item.action_id, wizardData?.wizard_id)];
      const actionRequest = runHubActionRequests[actionId(item.action_id, wizardData?.wizard_id)];
      if (actionResult) {
        return true;
      }
      if (actionRequest?.inProgress) {
        return true;
      }
      return false;
    });
  }, [wizardStep, resultsById, runHubActionRequests]);
  useEffect(() => {
    const wizardData = (wizardStep as WizardStep)?.data;
    const timerTimeoutEnd = wizardData?.timer_timeout_end;
    let interval: number | undefined;
    if (timerTimeoutEnd) {
      //const timeDelta = Math.ceil(moment(timerTimeoutEnd).diff(moment()) / 1000);
      const timeDelta = wizardData.timer_timeout_seconds || 0;
      setBlockingTimeout(timeDelta);
      interval = window.setInterval(() => {
        setBlockingTimeout((time) => {
          if (time <= 1) {
            clearInterval(interval);
            if (customerId && wizardData.pseudo_result) {
              readWizardStep(customerId, {
                type: 'wizard_step',
                data: {
                  wizard_id: wizardData.wizard_id,
                  result: wizardData.pseudo_result,
                },
              });
            }
          }
          return time - 1;
        });
      }, 1000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [customerId, readWizardStep, wizardStep]);

  const [optionsValues, setOptionsValues] = useState<
    Record<
      string,
      {
        name: string;
        type: string;
        value: string | boolean | string[];
      }
    >
  >({});

  if (!wizardStep || wizardStep.type !== WizardTypes.WIZARD_STEP) {
    return null;
  }

  const wizard: WizardStep = wizardStep;

  const pageTitle = wizard.data.title;
  const title = wizard.data.title_step;
  const description = wizard.data.description;

  const leftButtons = [];
  const rightButtons = [];
  const rightOuterButtons = [];
  const contentOptions = [];

  if (blockingTimeout > 0) {
    rightButtons.push(<span style={{ marginRight: '12px' }}>Attendi {blockingTimeout} secondi </span>);
  }

  for (let i = 0; i < wizard.data.options.length; i++) {
    const option = wizard.data.options[i];

    if (option) {
      if (option.type === 'button') {
        const button = option;

        const { name, type } = button;

        if (button?.name) {
          const value = typeof button.value === 'undefined' ? '' : button.value;
          const buttonComponent = (
            <WizardButton
              key={button.name}
              confirm={button.confirm ?? false}
              disabled={
                (button.side === 'right' /* && !enableRightNavigation */ &&
                  wizard.data.results?.option?.name !== button.name) ||
                (blockingTimeout > 0 && button.side !== 'right_outer') ||
                (button.name === 'Indietro' && !!button?.disabled)
              }
              onClick={() => {
                setClickedButton({ value: value, name: name, type: type });
                if (button.name === 'Indietro' && checkStatusAction() && !wizardStep.data.override) {
                  setErrorDialogOpen(true);
                  return;
                }
                if (customerId) {
                  // Handle call to timeout. Ignorare se intietro salta e chiudi.
                  const ignoredButtons = ['Indietro', 'Salta', 'Chiudi'];
                  if (
                    wizardStep.data.timer_timeout_seconds &&
                    !wizardStep.data.timer_timeout_end &&
                    !ignoredButtons.includes(name)
                  ) {
                    readWizardStep(customerId, {
                      type: 'wizard_step',
                      data: {
                        wizard_id: wizardStep.data.wizard_id,
                        timer_timeout: true,
                        pseudo_result: [{ value, name, type }, ...Object.values(optionsValues)],
                      },
                    });
                  } else {
                    readWizardStep(customerId, {
                      type: 'wizard_step',
                      data: {
                        wizard_id: wizardStep.data.wizard_id,
                        result: [{ value, name, type }, ...Object.values(optionsValues)],
                      },
                    });
                  }
                }
              }}
              loading={clickedButton?.value === button.value && readWizardStepRequest.inProgress}
              confirmDialogTitle={button.name}
            >
              {button?.name}
            </WizardButton>
          );

          if (button.side === 'left') {
            leftButtons.push(buttonComponent);
          } else if (button.side === 'right_outer') {
            rightOuterButtons.push(buttonComponent);
          } else {
            // If side is missing, show button on right
            rightButtons.push(buttonComponent);
          }
        }
      } else if (option.type === 'picklist') {
        const pickList = option;

        if (pickList) {
          contentOptions.push(
            <WizardPickList
              pickList={pickList}
              onChange={(values) => {
                setOptionsValues((v) => ({
                  ...v,
                  [pickList.name]: {
                    ...pickList,
                    value: values,
                  },
                }));
              }}
            />
          );
        }
      } else if (option.type === 'textbox') {
        const textbox = option;

        if (textbox) {
          contentOptions.push(
            <Box sx={{ width: '100%', pt: 2 }}>
              <TextField
                sx={{ minWidth: 300 }}
                label={textbox.value.description}
                required={textbox.value.required}
                onChange={(e) => {
                  setOptionsValues((v) => ({
                    ...v,
                    [textbox.name]: {
                      ...textbox,
                      value: e.target.value,
                    },
                  }));
                }}
              />
            </Box>
          );
        }
      } else if (option.type === 'checkbox') {
        const checkbox = option;

        if (checkbox) {
          contentOptions.push(
            <Box sx={{ width: '100%' }}>
              <FormControlLabel
                value={checkbox.name}
                control={
                  <Checkbox
                    onChange={(e) => {
                      setOptionsValues((v) => ({
                        ...v,
                        [checkbox.name]: {
                          ...checkbox,
                          value: e.target.checked,
                        },
                      }));
                    }}
                  />
                }
                label={checkbox.value.description}
                labelPlacement="end"
              />
            </Box>
          );
        }
      }
    }
  }

  return (
    <WizardPrimaryCard>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={errorDialogOpen}
        onClose={handleErrorDialogClose}
        aria-labelledby="action-dialog"
        scroll="paper"
      >
        <DialogTitle>{t('wizard:backButtonAlert:title')}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: 'black' }}>{t('wizard:backButtonAlert:body')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorDialogClose}>{t('common:close')}</Button>
        </DialogActions>
      </Dialog>
      <Scroller>
        <WizardHeader wizard={wizard} />
        <WizardPageTitle title={pageTitle} rightOuterButtons={[]} />
        <WizardOutdatedAlert wizard={wizard} />
        <WizardLoader>
          <WizardInnerCard actions={<WizardCardActions left={leftButtons} right={rightButtons} />}>
            <WizardTitle title={title} buttons={rightOuterButtons} />
            <WizardDescription description={description} />
            {contentOptions}
            <WizardActions
              actionsResults={wizard.data.actions_results}
              actions={wizard.data.actions}
              wizardId={wizard.data.wizard_id}
            />
            <WizardSuggestions suggestions={wizard.data.suggestions} />
          </WizardInnerCard>
          <WizardCharts chartList={wizard.data.chart_list} />
        </WizardLoader>
      </Scroller>
    </WizardPrimaryCard>
  );
}
