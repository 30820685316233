export type AuthGroup = { role: string; level: number; label: string; visible?: boolean };
export enum AuthRoles {
  MYTEAMEXT = 'MYTEAMEXT',
  MYTEAM = 'MYTEAM',
  BASE = 'BASE',
  PW = 'PW',
  MTTLSWITCHSQUAD = 'MTTLSWITCHSQUAD',
  SWAT = 'SWAT',
  SWATTL = 'SWATTL',
  TVSTD = 'TVSTD',
  QLEGACY = 'QLEGACY',
  SWATSPEC = 'SWATSPEC',
  SMC = 'SMC',
  BSNAASS = 'BSNAASS',
  MNG = 'MNG',
  UKTRIAL = 'UKTRIAL',
}
export const authGroups: AuthGroup[] = [
  { role: AuthRoles.MYTEAMEXT, level: 1, label: 'MyTeam', visible: true },
  { role: AuthRoles.MYTEAM, level: 1, label: 'MyTeam' },
  { role: AuthRoles.BASE, level: 1, label: 'Fulfillment' },
  { role: AuthRoles.PW, level: 2, label: 'Fulfillment' },
  { role: AuthRoles.MTTLSWITCHSQUAD, level: 1, label: 'MyTeam TL' },
  { role: AuthRoles.SWAT, level: 3, label: 'SWAT', visible: true },
  { role: AuthRoles.SWATTL, level: 3, label: 'SWAT TL' },
  { role: AuthRoles.TVSTD, level: 1, label: 'Service TV', visible: true },
  { role: AuthRoles.QLEGACY, level: 1, label: 'Sky Q', visible: true },
  { role: AuthRoles.SWATSPEC, level: 4, label: 'SWAT Special', visible: true },
  { role: AuthRoles.SMC, level: 4, label: 'CSO', visible: true },
  { role: AuthRoles.BSNAASS, level: 4, label: 'BSN & Training', visible: true },
  { role: AuthRoles.MNG, level: 100, label: 'MNG', visible: true },
  { role: AuthRoles.UKTRIAL, level: 100, label: 'UK Trial' },
].map((group) => ({ ...group, role: group.role.toLowerCase() }));

/*
 * allowedRoles: grants the access only if the user has at least one of the role.
 * grants access only if the user has at least a group outside those role.
 * examples:
 * 1.
 * deniedRoles = [A]
 * user.roles: [A,B] -> Allowed
 * user.roles: [A] -> Denied
 * 2.
 * denedRoles = [A,B]
 * user.roles = [A] -> Denied
 * user.roles = [A,C] -> Allowed
 * user.roles = [A,B] -> Denied
 *
 */

export type RequiredGrants = {
  allowedRoles?: string[];
  deniedRoles?: string[];
  redFlags?: string[];
  exactLevel?: number;
  minLevel?: number;
  maxLevel?: number;
};

export const SEARCH_CUSTOMER_GRANTS: RequiredGrants = {
  deniedRoles: ['MYTEAMEXT', 'MYTEAM'],
};

export const SEARCH_CUSTOMER_BB_GRANTS: RequiredGrants = {
  deniedRoles: ['MYTEAMEXT', 'MYTEAM', 'TVSTD', 'QLEGACY'],
};

export const SEARCH_CUSTOMER_TV_GRANTS: RequiredGrants = {
  allowedRoles: ['TVSTD', 'BSNAASS', 'MNG'],
};

export const SEARCH_CUSTOMER_Q_GRANTS: RequiredGrants = {
  allowedRoles: ['QLEGACY', 'TVSTD', 'MNG'],
};

export const EXECUTE_TEST_GRANTS: RequiredGrants = {
  deniedRoles: ['MYTEAMEXT'],
};

export const MAP_T_HISTORY_GRANTS: RequiredGrants = {
  minLevel: 100,
};

export const TIMELINE_GRANTS: RequiredGrants = {
  redFlags: ['UKTRIAL'],
};
export const MSO_GRANTS: RequiredGrants = {
  minLevel: 2,
  redFlags: ['UKTRIAL'],
};
